import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import dayjs from "dayjs";

const Player = ({ stream, type, subtitle, title, showDebug }) => {
  const playerRef = useRef();
  const [isPlaying, setPlaying] = useState(false);
  const [playStatus, setPlayStatus] = useState(null);

  const handlePlayStop = () => {
    setPlaying(!isPlaying);
  };

  const handleOnStart = () => {
    setPlaying(true);
  };

  const handleOnEnd = (e) => {
    console.log("handleOnEnd", e);

    if (e.type === "error" || e === "hlsError") {
      console.error("handleOnEnd Error", e);
      setTimeout(() => {
        setPlaying(false);
      }, 250);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: title,
        artist: subtitle,
      });
    }
  }, [isPlaying, subtitle, title]);

  const hlsStats = playerRef?.current?.getInternalPlayer("hls") || null;

  return (
    <>
      <div className="hidden">
        <ReactPlayer
          ref={playerRef}
          url={isPlaying ? stream : null}
          playing={isPlaying}
          onStart={handleOnStart}
          onPause={handleOnEnd}
          onError={handleOnEnd}
          volume={1}
          onProgress={setPlayStatus}
          config={{
            file: {
              forceAudio: true,
              forceHLS: type === "hls" ? true : false,
              hlsOptions: {
                lowLatencyMode: false,
                liveSyncDurationCount: 1,
                maxBufferLength: 5,
                debug: false,
              },
            },
          }}
        />
      </div>

      <div className="w-96 h-18 bg-white rounded-lg mx-4 my-4 pb-2">
        <div className="w-full px-4 ">
          <div className="flex justify-between">
            <div className="flex flex-col justify-center">
              <p className="text-lg text-grey-darkest font-medium">{title}</p>
              <p className="text-sm text-grey mt-1">{subtitle}</p>
            </div>

            <div
              className="text-white p-4 rounded-full bg-red-light shadow-lg"
              onClick={handlePlayStop}
            >
              {isPlaying ? <IconStop /> : <IconPlay />}
            </div>
          </div>
        </div>

        {showDebug && (
          <div
            className="px-4 py-2 text-xs flex justify-between"
            style={{ fontSize: "9px" }}
          >
            <div>
              <span>
                {dayjs(
                  hlsStats?.streamController?.fragPlaying?.rawProgramDateTime
                ).format("HH:mm:ss")}
              </span>
              {" / "}
              <span>{dayjs().format("HH:mm:ss")}</span>
            </div>

            <div>{hlsStats?.streamController?.fragPlaying?.relurl}</div>

            <div>
              <span>{Math.round(playStatus?.played * 100).toString()}%</span>
              <span> </span>
              <span>
                (<span>{Math.round(playStatus?.playedSeconds).toString()}</span>
                <span>/</span>
                <span>{Math.round(playStatus?.loadedSeconds).toString()}</span>)
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Player.propTypes = {
  stream: PropTypes.string.isRequired,
  type: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Player.defaultProps = {
  type: "hls",
  title: "---",
  subtitle: "...",
};

const IconPlay = () => {
  return (
    <svg
      className="w-8 h-8 fill-red-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path d="M24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13V38.13zM56.34 66.35C51.4 63.33 45.22 63.21 40.17 66.04C35.13 68.88 32 74.21 32 80V432C32 437.8 35.13 443.1 40.17 445.1C45.22 448.8 51.41 448.7 56.34 445.7L344.3 269.7C349.1 266.7 352 261.6 352 256C352 250.4 349.1 245.3 344.3 242.3L56.34 66.35z"></path>
    </svg>
  );
};

const IconStop = () => {
  return (
    <svg
      className="w-8 h-8 fill-red-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path d="M320 64H64c-35.35 0-64 28.65-64 64v255.1c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V128C384 92.65 355.3 64 320 64zM352 384c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V128c0-17.64 14.36-32 32-32h256c17.64 0 32 14.36 32 32V384z"></path>
    </svg>
  );
};

export default Player;
